import type { FC } from 'react';
import { useRef } from 'react';
import styled from '@emotion/styled';
import { graphql, useFragment } from 'react-relay/hooks';
import { useTranslation } from '@pafcloud/i18n';
import { Breakpoint } from '@pafcloud/style';
import { Icon, InternalLink, Logo } from '@pafcloud/base-components';

import { useIsLoggedIn } from '@pafcloud/contexts';
import { BurgerComponent } from '../BurgerComponent';
import { HeaderIconButton, ButtonContent, IconNotificationBadge, IconWrapper } from '../header-icon-button';
import { MessageMenu } from '../menus/message-menu';
import type { HeaderContentQuirky_content$key } from './__generated__/HeaderContentQuirky_content.graphql';
import type { HeaderContentProps } from './shared';
import { StyledHeader } from './shared';
import { HeaderBackground } from './HeaderBackground';

const contentFragment = graphql`
  fragment HeaderContentQuirky_content on Query {
    player {
      sourceOfFunds @required(action: NONE) {
        status
      }
      identification @required(action: NONE) {
        status
      }
    }
  }
`;

const LogoLink = styled(HeaderIconButton)({
  marginRight: 12,

  [Breakpoint.LaptopOrLarger]: {
    display: 'none',
  },
}).withComponent(InternalLink);

const HeaderLogo = styled(Logo)({
  height: 28,
});

// We need to wrap this to be able to generate a unique class name for it.
const HeaderMenuButton = styled(HeaderIconButton)();

const Header = styled(StyledHeader)({
  justifyContent: 'space-between',
  boxShadow: 'unset',
  backgroundColor: 'unset',

  [Breakpoint.LaptopOrLarger]: {
    pointerEvents: 'none',
    [`${HeaderMenuButton}`]: {
      display: 'none',
    },
    [`${HeaderBackground}`]: {
      display: 'none',
    },
  },
});

const RightSection = styled.div({
  flex: 'auto',
  justifyContent: 'flex-end',
  display: 'flex',
  alignItems: 'inherit',

  [Breakpoint.LaptopOrLarger]: {
    '& > *': {
      pointerEvents: 'auto',
    },
  },
});

export const Burger = styled(BurgerComponent)({
  height: 23,
});

export const HeaderContent: FC<HeaderContentProps> = ({ isMainMenuOpen, setMainMenuOpen, ...props }) => {
  const content = useFragment<HeaderContentQuirky_content$key>(contentFragment, props.content);
  const { t } = useTranslation(['header', 'profile']);
  const headerRef = useRef<HTMLDivElement>(null);
  const isLoggedIn = useIsLoggedIn();
  const menuAriaLabel = isMainMenuOpen ? t('main-menu-button.close.aria-label') : t('main-menu-button.open.aria-label');
  const showNotificationBadge = Boolean(
    content.player?.sourceOfFunds.status === 'REQUIRED' || content.player?.identification.status === 'REQUIRED',
  );

  return (
    <Header ref={headerRef}>
      <HeaderBackground />

      <HeaderMenuButton
        onClick={() => setMainMenuOpen(!isMainMenuOpen)}
        aria-label={menuAriaLabel}
        aria-expanded={isMainMenuOpen}
        data-name="main-menu-button"
      >
        <ButtonContent>
          {showNotificationBadge && (
            <IconNotificationBadge severity="high">
              <Icon name="exclamation" size="1em" />
            </IconNotificationBadge>
          )}

          <IconWrapper>
            <Burger open={isMainMenuOpen} />
          </IconWrapper>
        </ButtonContent>
      </HeaderMenuButton>

      <RightSection>
        {isLoggedIn && <MessageMenu />}

        <LogoLink aria-label={t('logo.aria-label')} href="/">
          <HeaderLogo />
        </LogoLink>
      </RightSection>
    </Header>
  );
};
