import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,
  Text: `${Color.Primitive.SecondaryContrast}80`,
  Link: Color.Primitive.SecondaryContrast,
  Bullets: `${Color.Primitive.SecondaryContrast}80`,
  Background: Color.Primitive.Accent,
  BrandLogoText: Color.Primitive.SecondaryContrast,
};
