import { createTransparentColor } from '../create-transparent-color';
import type { ColorDefinition } from './color-types';

const QuirkyPalette = {
  // Primary Colors
  Yellow: '#E2E504',
  YellowLight: '#F5F836',
  YellowHighlight: '#FEFF8E',

  // Alternate Colors
  Pink: '#FF206E',
  PinkLight: '#FE4586',
  PinkHighlight: '#D9356F',

  //Gradient blue
  Blue: '#2863D8',
  BlueBlend: '#2c8de4',
  BlueCyan: '#35DCFB',

  BluishWhite: '#f2f5f9',

  // Neutral Colors
  Dark: '#898F8E',
  Darker: '#131F1E',
  Neutral: '#787F83',
  Lighter: '#F7F7F7',
  White: '#ffffff',
};

const Primitive: ColorDefinition['Primitive'] = {
  Primary: QuirkyPalette.Yellow,
  PrimaryTint: QuirkyPalette.YellowHighlight,
  PrimaryContrast: QuirkyPalette.Darker,

  Secondary: QuirkyPalette.Pink,
  SecondaryTint: QuirkyPalette.PinkLight,
  SecondaryContrast: QuirkyPalette.White,

  Accent: QuirkyPalette.Blue,
  AccentTint: QuirkyPalette.BlueBlend,
  AccentContrast: QuirkyPalette.White,

  Gradient: `linear-gradient(45deg, ${QuirkyPalette.BlueCyan}, ${QuirkyPalette.Blue}) border-box`,
  GradientTint: `linear-gradient(45deg, ${QuirkyPalette.BlueCyan}, ${QuirkyPalette.BlueBlend}) border-box`,
  GradientContrast: QuirkyPalette.White,
};

const TextColors: ColorDefinition['TextColors'] = {
  HeadingText: QuirkyPalette.Blue,
  BodyText: QuirkyPalette.Darker,
  MutedText: QuirkyPalette.Neutral,
  HighlightedText: QuirkyPalette.Blue,
  LinkText: QuirkyPalette.Blue,
  ErrorText: QuirkyPalette.Pink,
  DisabledText: '#9B9DA0',
};

const Surface: ColorDefinition['Surface'] = {
  Base: {
    Background: createTransparentColor(QuirkyPalette.White, 0.98),
    Foreground: QuirkyPalette.Darker,
    Dimmed: createTransparentColor(QuirkyPalette.Blue, 0.3),
  },
  Nested: {
    Background: QuirkyPalette.BluishWhite,
    Foreground: QuirkyPalette.Darker,
    Dimmed: createTransparentColor(QuirkyPalette.Blue, 0.2),
  },
  Disabled: {
    Background: QuirkyPalette.Dark + '80',
    Foreground: QuirkyPalette.Neutral,
    Dimmed: createTransparentColor(QuirkyPalette.Dark, 0.15),
  },
  Floating: {
    Background: QuirkyPalette.Lighter,
    Foreground: QuirkyPalette.Darker,
    Dimmed: createTransparentColor(QuirkyPalette.Dark, 0.2),
  },
};

const Signal: ColorDefinition['Signal'] = {
  Success: QuirkyPalette.Yellow,
  SuccessContrast: QuirkyPalette.White,

  Info: QuirkyPalette.BlueBlend,
  InfoContrast: QuirkyPalette.White,

  Attention: QuirkyPalette.BlueBlend,
  AttentionContrast: QuirkyPalette.White,

  Danger: QuirkyPalette.Pink,
  DangerContrast: QuirkyPalette.White,
};

const CornerRadius: ColorDefinition['CornerRadius'] = {
  Small: '4px',
  Base: '12px',
  Large: '32px',
};

const Elevation: ColorDefinition['Elevation'] = {
  Level1: `0 2px 3px -1px var(--shadow-color, ${createTransparentColor(QuirkyPalette.Darker, 0.1)})`,
  Level2: `0 4px 6px -1px var(--shadow-color, ${createTransparentColor(QuirkyPalette.Darker, 0.15)})`,
  Level3: `0 8px 18px -2px var(--shadow-color, ${createTransparentColor(QuirkyPalette.Darker, 0.15)}), 0 3px 6px 0 var(--shadow-color, ${createTransparentColor(QuirkyPalette.Darker, 0.15)})`,
};

const General: ColorDefinition['General'] = {
  Bonus: QuirkyPalette.Yellow,
  BonusContrast: QuirkyPalette.Darker,
};

const Layout: ColorDefinition['Layout'] = {
  Background: QuirkyPalette.Lighter,
  BrandBorder: Primitive.Gradient,
  BrowserTheme: Primitive.Accent,
  LoadingColor: Primitive.Primary,
  Overlay: Primitive.Accent + 'CC',
  Sidebar: {
    Background: Primitive.Gradient,
    Foreground: QuirkyPalette.White,
  },
};

const Hero: ColorDefinition['Hero'] = {
  Heading: QuirkyPalette.White,
  Text: QuirkyPalette.White,
  TextHighlight: Primitive.Primary,

  Button: {
    Solid: Primitive.Primary,
    Rest: Primitive.Primary,
    Hover: Primitive.PrimaryTint,
    Active: Primitive.PrimaryTint,
    Text: Primitive.PrimaryContrast,
  },
};

export const Color: ColorDefinition = {
  TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,
};
