import { Color } from '@pafcloud/style';

// The menu on quirky theme is inverted this is why foreground and background color has been swapped

export const Colors = {
  Text: Color.Surface.Base.Background,
  TextHover: Color.Surface.Base.Background,
  TextActive: Color.Primitive.Primary,
  TextShadow: `1px 2px rgba(0,0,0,0.2)`,

  Icon: undefined,
  IconActive: undefined,

  BackgroundHover: `${Color.Primitive.Accent}33`,
  BackgroundActive: undefined,

  ItemDivider: undefined,
  ItemActiveShadow: undefined,

  SubMenuBackground: undefined,
  SubMenuRuler: 'rgba(255,255,255,0.2)',
};

export const FontStyle = {
  TextTransform: undefined,
  Weight: 'bold',
  WeightHover: 'bold',
  WeightActive: 'bold',
} as const;
