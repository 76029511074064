import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,

  BackgroundBlur: Color.Primitive.Gradient,
  Background: Color.Primitive.Gradient,

  Icon: Color.Primitive.Primary,
  IconFocus: Color.Primitive.PrimaryTint,

  NotificationBackground: Color.Primitive.Secondary,
  NotificationText: Color.Primitive.SecondaryContrast,
};
