import LogoNoText from './svg/prank-logo-no-text.svg';
import LogoVertical from './svg/prank-logo-vertical.svg';
import type { LogotypeType } from './logo-variants-types';

export const Logos: LogotypeType = {
  Horizontal: LogoVertical,
  HorizontalInverse: LogoVertical,
  Vertical: LogoVertical,
  VerticalInverse: LogoVertical,
  Icon: LogoNoText,
  IconInverse: LogoNoText,
};
